import React from 'react';
import * as Sentry from '@sentry/browser';

import polyfills from "polyfills";

import 'libs/sentry';
import App from 'components/App';

import { createRoot } from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

async function showReactDOM() {
  try {
    await Promise.all(polyfills);
  } catch (error) {
    Sentry.addBreadcrumb({
      category: 'polyfills',
      message: 'Unexpected error: polyfills failed to install',
      level: 'error',
    });

    Sentry.captureException(error);
  } finally {
    Promise.all([import(`./themes/${process.env.REACT_APP_THEME}`)])
      .then(([themeModule]) => {
        const container = document.getElementById('root');
        if (container) {
          const root = createRoot(container);
          root.render(<App theme={themeModule.default} />);
        }
      })
      .catch((e) => {
        throw new Error(e as string);
      });

    // If you want to start measuring performance in your app, pass a function
    // to log results (for example: reportWebVitals(console.log))
    // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
    reportWebVitals();
  }
}

showReactDOM();

