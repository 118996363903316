export const AVAILABLE_LNGS = [
  'ru',
  'en',
  'pl',
  'az',
  'kk',
  'uk',
  'ky',
  'uz',
] as const;
export const FALLBACK_LNG = 'en';

export type Language = (typeof AVAILABLE_LNGS)[number];

export const LANGUAGES: Record<
  Language,
  {
    name: string;
    code: Language;
  }
> = {
  ru: {
    name: 'Русский',
    code: 'ru',
  },
  en: {
    name: 'English',
    code: 'en',
  },
  uk: {
    name: 'Українська',
    code: 'uk',
  },
  pl: {
    name: 'Polski',
    code: 'pl',
  },
  az: {
    name: 'Azərbaycan',
    code: 'az',
  },
  kk: {
    name: 'Kazakh',
    code: 'kk',
  },
  uz: {
    name: 'Oʻzbek',
    code: 'uz',
  },
  ky: {
    name: 'Кыргызча',
    code: 'ky',
  },
};
